<template>
	<div class="list" v-if="modelValue">
		<div class="filter">
			<p class="filter-company" v-show="current_user.app_admin==1" @click="companyShow=!companyShow">
				<span class="company-name">{{companyList.find(one=>one.dept_id==filter.dept_id)?.corp_name}}</span>
				<span class="arrow icon-2"></span>
			</p>
			<div class="search-box">
				<span class="search-icon icon-5"></span>
				<input @compositionstart="handleCompositionStart"
					   @compositionend="handleCompositionEnd" ref="input" class="input" type="text" v-model="filter.value" placeholder="搜索规则名称" @input="input"/>
				<span class="clear-icon icon-10" v-show="filter.value.length>0" @click="clear"></span>
			</div>
		</div>
		<div class="list-box" @scroll="scroll">
			<div class="list-rule" v-for="item in list" @click="ruleDetail(item)">
				<div class="rule">
					<p class="rule-name">{{item.name}}</p>
					<p class="rule-id">报警条件：{{item.mapping_name}}{{item.cond}}{{item.value}}</p>
				</div>
				<div class="auth">
					<span class="arrow icon-6"></span>
				</div>
			</div>
			<no-data v-show="list.length==0"></no-data>
		</div>
		<span class="add icon-11" @click="addWarning" v-show="current_user.app_admin!=3"></span>
		<popup v-model="companyShow" title="企业列表">
			<template #content>
				<div class="company-list">
					<p class="list-one" :class="{selected:item.dept_id==filter.dept_id}" v-for="item in companyList" @click="clickItem(item)">
						<span class="corp-name">{{item.corp_name}}</span>
					</p>
					<no-data v-show="companyList.length==0"></no-data>
				</div>
			</template>
		</popup>
		<popup v-model="addRuleShow" height="100vh" :round="false" :showTop="false">
			<template #content>
				<div class="add-user">
					<div class="content">
						<div class="col">
							<div class="col-name">规则名称</div>
							<div class="col-content">
								<new-input v-model="rule.name" placeholder="请输入规则名称"></new-input>
							</div>
						</div>
						<div class="col">
							<div class="col-name">关联监控点</div>
							<div class="col-content">
								<new-select v-model="rule.device_id" placeholder="请选择关联监控点" title="请选择关联监控点" :list="deviceList" @requestList="()=>getDeviceList()"></new-select>
							</div>
						</div>
						<div class="col">
							<div class="col-name">报警条件</div>
							<div class="col-content vertical">
								<div class="col-content-top">
									<new-select :disabled="!rule.device_id" v-model="rule.mapping_id" placeholder="请选择数据映射" title="请选择数据映射" :list="mapList" :defaultProp="{name:'key_mapping',value:'ID'}" @requestList="()=>getMapList()"></new-select>
									<new-select :clearable="false" v-model="rule.cond" placeholder="请选择报警条件" title="请选择报警条件" :list="cond" :defaultProp="{name:'name',value:'value',tip:'tip'}" :showTip="true" :style="{marginLeft: '.2rem'}"></new-select>
								</div>
								<new-input v-model="rule.value" placeholder="请输入报警触发值"></new-input>
							</div>
						</div>
						<!--<div class="col">-->
							<!--<div class="col-name">报警内容</div>-->
							<!--<div class="col-content">-->
								<!--<textarea class="textarea" rows="6" v-model="rule.waring_content" placeholder="请输入报警内容"></textarea>-->
							<!--</div>-->
						<!--</div>-->
						<div class="border"></div>
						<div class="col">
							<div class="col-name">通知对象</div>
							<div class="col-content">
								<select-people :dept_id="filter.dept_id" :userInfo="rule.notice_user" @people-data="peopleData"></select-people>
							</div>
						</div>
						<div class="col">
							<div class="col-name">通知方式</div>
							<div class="col-content">
								<div class="notice-type">
									<span class="notice-one" :class="{selected:rule.notice_type==1}" @click="rule.notice_type=1">短信通知</span>
									<span class="notice-one" :class="{selected:rule.notice_type==2}" style="margin-left:.1rem" @click="rule.notice_type=2">钉钉通知</span>
								</div>
							</div>
						</div>
						<div class="col">
							<div class="col-name">通知频率</div>
							<div class="col-content" style="align-items: center">
								<new-input type="number" v-model="rule.repeat" placeholder="请输入通知频率" style="flex: 1;margin-right: .05rem"></new-input>
								<span>小时内不再重复通知</span>
							</div>
						</div>
					</div>
					<div class="opt">
						<span class="btn default" @click="cancel">取消</span>
						<span class="btn" @click="addRule" style="margin-left: .1rem">保存</span>
					</div>
				</div>
			</template>
		</popup>
		<popup v-model="detailRuleShow" height="60vh" :title="rule.name">
			<template #content>
				<div class="add-user">
					<div class="content">
						<div class="row">
							<div class="row-name">报警条件</div>
							<div class="row-content">
								{{rule.mapping_name}}{{rule.cond}}{{rule.value}}
							</div>
						</div>
						<!--<div class="row">-->
							<!--<div class="row-name">报警内容</div>-->
							<!--<div class="row-content">-->
								<!--{{rule.waring_content}}-->
							<!--</div>-->
						<!--</div>-->
						<div class="row">
							<div class="row-name">关联监控点</div>
							<div class="row-content">
								{{deviceList.find(one=>rule.device_id==one.ID)?.name}}
							</div>
						</div>
						<div class="row">
							<div class="row-name">负责人</div>
							<div class="row-content">
								{{rule.device_charge_user}}
							</div>
						</div>
						<div class="row">
							<div class="row-name">通知对象</div>
							<div class="row-content">
								{{rule.notice_user.map(one=>one.name).join(',')}}
							</div>
						</div>
						<div class="row">
							<div class="row-name">通知方式</div>
							<div class="row-content">
								{{rule.notice_type==1?'短信通知':'钉钉通知'}}
							</div>
						</div>
						<div class="row">
							<div class="row-name">通知频率</div>
							<div class="row-content">
								{{rule.repeat}}小时内不再重复通知
							</div>
						</div>
						<div class="row">
							<div class="row-name">更新时间</div>
							<div class="row-content">
								{{rule.UpdatedAt}}
							</div>
						</div>
					</div>
					<div class="opt" v-show="current_user.app_admin!=3">
						<span class="btn danger" @click="deleteWarning(rule)">删除</span>
						<span class="btn" @click="detailRuleShow=false;addRuleShow=true" style="margin-left: .1rem">编辑</span>
					</div>
				</div>
			</template>
		</popup>
	</div>
</template>

<script>
	import {defineComponent, toRefs, reactive,computed,watch} from "vue"
	import {useRouter} from "vue-router"
	import {useStore} from "vuex"
	import {Dialog,Toast} from "vant"
	import api from "@/api/methods"
	import myPublic from "@/utils/public"
	import * as dd from 'dingtalk-jsapi';
	import formValid from '@/components/input/formValid';
	export default defineComponent({
		name: "device",
		props: {
			modelValue: {
				type: Boolean,
				default: false
			}
		},
		mixins:[formValid],
		setup(props, {emit}) {
			const router = useRouter();
			const store = useStore();
			const state = reactive({
				app_admin_list:[
					{
						name:'超级管理员',
						value:1,
						color:'#ffa61a',
						border_color:'#ffa61a',
					},{
						name:'管理员',
						value:2,
						color:'#999',
						border_color:'#999',
					},{
						name:'企业成员',
						value:3,
						color:'#999',
						border_color:'#999',
					},
				],
				companyShow:false,
				companyList:[],
				list:[],
				deviceList:[],
				mapList:[],
				page:1,
				page_size:15,
				total:0,
				next_time:Date.now(),
				filter:{
					value:'',
					dept_id:'',
				},
				rule:{
					"id":'',
					"device_id": '',
					"name": "",
					"mapping_id": '',
					"cond": ">",
					"value": "",
					"waring_content": "",
					"notice_user": [],
					"notice_type": 2,
					"repeat": 2,
					"UpdatedAt": '',
					"device_charge_user": '',
				},
				cond:[
					{
						name:'＞',
						value:'>',
						tip:'(大于)'
					},
					{
						name:'＜',
						value:'<',
						tip:'(小于)'
					},
					{
						name:'≥',
						value:'>=',
						tip:'(大于等于)'
					},
					{
						name:'≤',
						value:'<=',
						tip:'(小于等于)'
					},{
						name:'＝',
						value:'=',
						tip:'(等于)'
					},{
						name:'≠',
						value:'!=',
						tip:'(不等于)'
					}
				],
				addRuleShow:false,
				detailRuleShow:false,
				isPc:dd.pc
			});
			const current_user = computed(()=>{
				return store.state.user.current_user
			});
			watch(()=>props.modelValue,(val)=>{
				if(val){
					init();
				}
			},{immediate:true,deep:true});
			function peopleData(user) {
				state.rule.notice_user = user;
			}
			function getCompanyList(page=1) {
				let p ={
					page:page,
					page_size:999
				};
				state.page=page;
				return api.company.getCompanyList(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							res.data.list.forEach((item)=>{
								item.admin = item.admin_user?item.admin_user[0].name:'--';
							});
							if(!state.filter.dept_id){
								if(current_user.value.dept_id){
									state.filter.dept_id = current_user.value.dept_id;
								}else{
									state.filter.dept_id = res.data.list[0]?.dept_id;
								}
							}
							state.companyList = res.data.list;
						}
					}
					return true;
				})
			}
			function getDeviceList() {
				let p ={
					page:1,
					page_size:999
				};
				if(state.filter.dept_id){
					p.dept_id = state.filter.dept_id;
				}
				return api.device.deviceList(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							state.deviceList = res.data.list;
						}
					}
					return true;
				})
			}
			function getMapList() {
				let p ={
					page:1,
					page_size:999
				};
				if(state.rule.device_id){
					p.id = state.rule.device_id;
				}else{
					return Toast.fail({
						message: "请先选择关联监控点!",
					});
				}
				return api.device_warning.getMappingByDeviceId(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data)){
							state.mapList = res.data;
						}
					}
					return true;
				})
			}
			function getList(page=1) {
				let p ={
					page:page,
					page_size:state.page_size
				};
				state.page=page;
				if(state.filter.dept_id){
					p.dept_id = state.filter.dept_id;
				}
				if(state.filter.value){
					p.name = state.filter.value;
				}
				api.device_warning.deviceWarningList(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							if(page == 1){
								state.list = res.data.list;
							}else{
								state.list.push(...res.data.list);
							}
							state.total = res.data.total;
						}
					}
				})
			}
			function scroll(e) {
				if (e.target.clientHeight + e.target.scrollTop + 1 > e.target.scrollHeight) {
					if (Date.now() - state.next_time>500&&Math.ceil(state.total/state.page_size)>state.page) {
						state.next_time = Date.now();
						state.page++;
						getList(state.page);
					}
				}
			}
			function back(){
				router.go(-1);
			}
			function clickItem(item){
				if(state.filter.dept_id != item.dept_id){
					state.filter.dept_id = item.dept_id;
					state.rule.dept_id = item.dept_id;
				}
				state.companyShow = false;
				getList();
			}
			function clear(){
				state.filter.value = '';
				getList();
			}
			function deleteWarning(item) {
				Dialog.confirm({
					title: '操作提醒',
					message:`确定删除报警规则【${item.name}】？`
				}).then(() => {
					deviceWarningDelete(item);
				}).catch(() => {
					// on cancel
				});
			}
			function deviceWarningDelete(item) {
				let p = {
					id:item.id
				};
				api.device_warning.deviceWarningDelete(p).then((res)=>{
					if(res.code==0){
						Toast.success({
							message: "删除成功!",
						});
						state.detailRuleShow=false;
						getList();
					}
				})
			}
			function addWarning(){
				state.addRuleShow = true;
			}
			async function init(){
				await getCompanyList();
				getList();
			}
			return {
				...toRefs(state),
				current_user,
				back,
				getList,
				clickItem,
				clear,
				scroll,
				deleteWarning,
				peopleData,
				getDeviceList,
				getMapList,
				addWarning
			}
		},
		data(){
			return {
				isComposing:false
			}
		},
		methods:{
			handleCompositionStart() {
				this.isComposing = true;
			},
			handleCompositionEnd(event) {
				this.isComposing = false;
				this.input(event);
			},
			input(e){
				if(this.isComposing) return;
				this.getList();
			},
			cancel(){
				this.rule = {
					"id":'',
					"device_id": '',
					"name": "",
					"mapping_id": '',
					"cond": ">",
					"value": "",
					"waring_content": "",
					"notice_user": [],
					"notice_type": 2,
					"repeat": 2,
					"UpdatedAt": '',
					"device_charge_user": ''
				};
				this.addRuleShow = false;
			},
			addRule() {
				let p = {
					"device_id": this.rule.device_id,
					"name": this.rule.name,
					"mapping_id": this.rule.mapping_id,
					"cond": this.rule.cond,
					"value": this.rule.value,
					"waring_content": this.rule.waring_content,
					"notice_user": this.rule.notice_user.map(one=>one.user_id).join(','),
					"notice_type": this.rule.notice_type,
					"repeat": Number(this.rule.repeat)
				};
				if(this.rule.id){
					p.id = this.rule.id;
				}else{
					p.dept_id = this.filter.dept_id;
				}
				if(!this.rule.name){
					return this.$toast({
						message: "请填写规则名称！",
						type:'fail'
					});
				}
				if(!this.rule.device_id){
					return this.$toast({
						message: "请选择关联监控点！",
						type:'fail'
					});
				}
				if(!this.rule.mapping_id){
					return this.$toast({
						message: "请选择数据映射！",
						type:'fail'
					});
				}
				if(!this.rule.value){
					return this.$toast({
						message: "请输入报警触发值！",
						type:'fail'
					});
				}
				if(this.rule.notice_user.length==0){
					return this.$toast({
						message: "请选择通知对象！",
						type:'fail'
					});
				}
				api.device_warning[this.rule.id?'deviceWarningUpdate':'addDeviceWarning'](p).then((res)=>{
					if(res.code==0){
						this.cancel();
						this.getList();
					}
				})
			},
			ruleDetail(item) {
				let p ={
					id:item.ID,
				};
				api.device_warning.deviceWarningDetail(p).then((res)=>{
					if(res.code==0){
						this.rule.id = res.data.ID;
						this.rule.name = res.data.name;
						this.rule.device_id = res.data.device_id;
						this.rule.mapping_id = res.data.mapping_id;
						this.rule.mapping_name = res.data.mapping_name;
						this.rule.cond = res.data.cond;
						this.rule.value = res.data.value;
						this.rule.waring_content = res.data.waring_content;
						this.rule.notice_type = res.data.notice_type;
						this.rule.repeat = res.data.repeat;
						this.rule.UpdatedAt = myPublic.dateFormat.format(res.data.UpdatedAt);
						this.rule.notice_user = res.data.user_info?res.data.user_info:[];
						this.rule.device_charge_user = res.data.device_charge_user;
						this.getDeviceList();
						this.getMapList();
						this.detailRuleShow = true;
					}
				})
			}
		}
	})
</script>

<style scoped lang="less">
	.list{
		flex: 1;
		display: flex;
		flex-direction: column;
		border-top: .03rem solid #eef2f5;
		overflow: hidden;
		.filter{
			position: relative;
			width: calc(100% - .4rem);
			display: flex;
			align-items: center;
			flex-shrink: 0;
			padding: .1rem .2rem;
			background-color: #fff;
			.filter-company{
				max-width: 2rem;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				margin-right: .1rem;
				cursor: pointer;
			}
			.search-box{
				flex: 1;
				position: relative;
				display: inline-flex;
				align-items: center;
				overflow: hidden;
				.search-icon{
					position: absolute;
					left: .08rem;
					font-size: .16rem;
					color: #c4ccd9;
				}
				.input{
					flex: 1;
					height: .34rem;
					border-radius: 2em;
					background-color: #eef2f5;
					padding:0 .25rem 0 .3rem;
					overflow: hidden;
				}
				.clear-icon{
					position: absolute;
					right: .08rem;
					font-size: .16rem;
					color: #c4ccd9;
				}
			}
			.btn{
				display: inline-flex;
				align-items: center;
				height: .36rem;
				background-color: #2ca0f7;
				color: #fff;
				border-radius: 2em;
				padding:0 .1rem;
				margin-left: .1rem;
				cursor: pointer;
				.icon{
					font-size: .12rem;
					margin-right: .05rem;
				}
				&:active{
					opacity: .8;
				}
			}
		}
		.list-box{
			flex: 1;
			background-color: #fff;
			padding: 0 .2rem;
			overflow-y: auto;
			.list-rule{
				padding: .1rem 0;
				display: flex;
				border-bottom: 1px solid #efefef;
				cursor: pointer;
				.rule{
					flex: 1;
					overflow: hidden;
					.rule-name{
						min-width: 0;
						color: #333;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						font-size: .16rem;
						font-weight: bolder;
					}
					.rule-id{
						color: #999;
						display: flex;
						align-items: center;
						height: .3rem;
						overflow: hidden;
					}
				}
				.auth{
					display: inline-flex;
					align-items: center;
					.switch{
						color: #2aa0f7;
						margin: 0 .1rem;
						cursor: pointer;
					}
					.delete{
						color: #F56C6C;
						margin:0 .1rem;
						cursor: pointer;
					}
				}
				&:last-of-type{
					border-bottom: none;
				}
			}
			.col{
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: .24rem;
				color: #999;
			}
		}
		.add{
			position: absolute;
			bottom: .5rem;
			right: .3rem;
			display: flex;
			justify-content: center;
			align-items: center;
			width: .5rem;
			height: .5rem;
			border-radius: 50%;
			color: #fff;
			background-color: #2aa0f7;
			cursor: pointer;
			box-shadow: 0 0 15px rgba(0,0,0,.15);
			&:active{
				opacity: .8;
			}
		}
	}
	.company-list{
		height: 100%;
		padding: 0 .25rem;
		overflow-y: auto;
		.list-one{
			display: flex;
			align-items: center;
			height: .4rem;
			border-bottom: 1px solid #efefef;
			cursor: pointer;
			.corp-name{
				flex: 1;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
		.selected{
			.corp-name{
				color: #2aa0f7;
			}
		}
	}
	.add-user{
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		.content{
			flex: 1;
			overflow-y: auto;
			.col{
				padding: .05rem .15rem .15rem .15rem;
				.col-name{

				}
				.col-content{
					padding-top: .05rem;
					display: flex;
					overflow: hidden;
					.col-content-top{
						flex: 1;
						display: flex;
						overflow: hidden;
						margin-bottom: .1rem;
					}
				}
			}
			.row{
				display: flex;
				align-items: center;
				justify-content: space-between;
				min-height: .2rem;
				border-bottom: 1px solid #efefef;
				overflow: hidden;
				margin: 0 .25rem;
				padding: .1rem 0;
				font-size: .12rem;
				.row-name{
					width: .8rem;
					color: #949aa8;
				}
				.row-content{
					flex: 1;
					display: inline-flex;
					flex-wrap: wrap;
				}
			}
			.vertical{
				flex-direction: column;
			}
			.notice-type{
				display: flex;
				padding-top: .1rem;
				.notice-one{
					display: inline-flex;
					align-items: center;
					justify-content: center;
					width: 1rem;
					height: .32rem;
					border-radius: .04rem;
					background-color: #eef2f5;
					color: #999;
					cursor: pointer;
				}
				.selected{
					background-color: #2aa0f7;
					color: #fff;
				}
			}
			.textarea{
				flex: 1;
				padding: 0.1rem;
				border-radius: 0.04rem;
				background-color: #eef2f5;
				resize: none;
			}
		}
		.opt{
			flex-shrink: 0;
			display: flex;
			align-items: center;
			min-height: .5rem;
			border-top: 1px solid #efefef;
			padding: 0 .15rem;;
			.btn{
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				height: .4rem;
				border-radius: .04rem;
				background-color: #2aa0f7;
				color: #fff;
				font-size: .12rem;
				border: 1px solid transparent;
				cursor: pointer;
				&:active{
					opacity: .8;
				}
			}
			.default{
				background-color: #fff;
				color: #2aa0f7;
				border: 1px solid #2aa0f7;
			}
			.danger{
				color: #f56c6c;
				background: #fff;
				border-color: #fbc4c4;
			}
		}
	}
	.border{
		height: .06rem;
		background-color: #eef2f5;
	}
</style>
